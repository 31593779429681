import {Inject, Injectable} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {ScullyRoutesService} from '@scullyio/ng-lib';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SocialTagsService {

  public constructor(
    @Inject(DOCUMENT) private document,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scully: ScullyRoutesService,
    private meta: Meta
  ) {
  }

  readonly envUrlPrefix: string = environment.mainBaseUrl;

  readonly tagImage: string = 'assets/images/share_graphic.png';
  readonly siteName: string = 'iTAX';
  readonly userTwitter: string = '@iTAXThailand';
  readonly appIdFB: string = '864899953585198';

  setTitleAndTags(data) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary')
    ).subscribe(() => {
      this.scully.getCurrent().subscribe(
        () => {
          this.meta.updateTag({name: 'og:url', property: 'og:url', content: data.urlPrefix + this.router.url});
          this.meta.updateTag({name: 'og:site_name', property: 'og:site_name', content: this.siteName});
          this.meta.updateTag({name: 'og:app_id', property: 'og:app_id', content: this.appIdFB});
          this.meta.updateTag({name: 'og:title', property: 'og:title', content: data.tagSocialTitle});
          this.meta.updateTag({name: 'og:description', property: 'og:description', content: data.tagSocialDescription});
          this.meta.updateTag({name: 'og:type', property: 'og:type', content: 'website'});
          this.meta.updateTag({name: 'og:image', property: 'og:image', content: data.urlPrefix + '/' + this.tagImage});
          this.meta.updateTag({name: 'fb:app_id', property: 'fb:app_id', content: environment.fbAppID});
          this.meta.updateTag({name: 'twitter:url', content: data.urlPrefix + this.router.url});
          this.meta.updateTag({name: 'twitter:creator', content: this.userTwitter});
          this.meta.updateTag({name: 'twitter:site', content: this.userTwitter});
          this.meta.updateTag({name: 'twitter:title', content: data.tagSocialTitle.substring(0, 69)});
          this.meta.updateTag({name: 'twitter:description', content: data.tagSocialDescription.substring(0, 123)});
          this.meta.updateTag({name: 'twitter:image', content: data.urlPrefix + '/' + this.tagImage});
          this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        });
    });
  }

  setCanonicalURL() {
    this.removeCanonicalURL()
    let url = `${this.envUrlPrefix}${this.router.url}`
    let link: HTMLLinkElement = this.document.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', url)
    this.document.head.appendChild(link)

    let linkAlt: HTMLLinkElement = this.document.createElement('link')
    linkAlt.setAttribute('rel', 'alternate')
    linkAlt.setAttribute('hreflang', 'th')
    linkAlt.setAttribute('href', url)
    this.document.head.appendChild(linkAlt)
  }

  removeCanonicalURL() {
    let selector = 'link[rel="canonical"]'
    let link: HTMLLinkElement = this.document.head.querySelector(selector)
    if (link) {
      this.document.head.removeChild(link)
    }

    let selectorAlt = 'link[rel="alternate"]'
    let linkAlt: HTMLLinkElement = this.document.head.querySelector(selectorAlt)
    if (linkAlt) {
      this.document.head.removeChild(linkAlt)
    }
  }
}